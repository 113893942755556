import React, { useState } from "react";
import { styled } from "styled-components";
import LoginUserImage from '../images/login/219983.png'
import { Typewriter } from "react-simple-typewriter";
import { UserStore } from "../store/user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FaUser } from "react-icons/fa";
import { MdRemoveRedEye } from "react-icons/md";

// MUI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import axios from "axios";


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "./loader";

let LOGIN = {
    login: '',
    password: ''
}


const Login = () => {
    const [value, setValue] = React.useState(0);
    const [text, setText] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [passworShowAndHiddien, setPasswordShowAndHidden] = useState(false);
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function handleEmail(e) {
        e.preventDefault();
        setLoader(true);
        let emailLogin = document.getElementById('email').value;
        let emailPassword = document.getElementById('email_id').value;
        LOGIN.login = emailLogin;
        LOGIN.password = emailPassword;

        axios.post('https://e-libraryrest.samdu.uz/api/User/Login', LOGIN)
            .then(res => {
                dispatch(UserStore(res.data.result));
                localStorage.setItem('token', res.data.result.access_token);
                toast.success('Tizimga muvafaqatli kirdingiz');
                navigate('/profile');
                setLoader(false);
            })
            .catch(err => {
                console.log(err);
                setLoader(false);
                toast.error('Tizimga kirishda xatolik bor!');
            })
    }

    function handleHemis(e) {
        e.preventDefault();
        setLoader(true);
        let emailLogin = document.getElementById('hemis').value;
        let emailPassword = document.getElementById('hemis_id').value;
        LOGIN.login = emailLogin;
        LOGIN.password = emailPassword;

        axios.post('https://e-libraryrest.samdu.uz/api/User/LoginWithHemis', LOGIN)
            .then(res => {
                dispatch(UserStore(res.data.result));
                localStorage.setItem('token', res.data.result.access_token)
                navigate('/profile');
                setLoader(false);
                toast.success('Tizimga muvafaqatli kirdingiz');
            })
            .catch(err => {
                console.log(err);
                setLoader(false);
                toast.error('Tizimga kirishda xatolik bor!');
            })
    }

    return (
        <LoginBox>
            {loader ? <Loader /> : null}
            <div className="container">
                <LoginGrid>
                    <LoginLeft>
                        <LoginLeftBox>
                            <LoginLeftHeader data-aos="zoom-in">
                                <h1>{t('UNIVER_NAME')}</h1>
                            </LoginLeftHeader>
                            <LoginLeftText>
                                <p data-aos="zoom-in">{t('UNIVER_NAME_WEB')}</p>
                                <hr />
                                <AnimationText>
                                    <p>{t('UNIVER_NAME_CATEGORY_HEADER')} <br/><span style={{ color: '#16a34a' }}>
                                        <Typewriter
                                            words={[t('BOOKS'), t('ARTICLES'), t('DISSERTATIONS'), t('MONOGRAPHS'), t('MANUSCRIPTS')]}
                                            loop
                                            cursor
                                            delay={100}
                                            onType={(word) => setText(word)}
                                        >
                                            <p>{text}</p>
                                        </Typewriter>
                                        </span>
                                    </p>
                                </AnimationText>
                            </LoginLeftText>
                        </LoginLeftBox>
                    </LoginLeft>
                    <LoginRight>
                        <LoginRightBox>
                            <LoginHeader>
                                <LoginHeaderImg data-aos="zoom-in">
                                    <img src={LoginUserImage} alt="login_user" />
                                </LoginHeaderImg>
                                <LoginHeaderTitle>
                                    <h5>{value === 0 ? 'EMAIL' : 'HEMIS'}</h5>
                                </LoginHeaderTitle>
                            </LoginHeader>
                            <LoginForm>
                                <Tabs sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} value={value} onChange={handleChange} aria-label="icon label tabs example">
                                    <Tab sx={{ width: '50%', color: '#3b82f6' }} icon={<MarkunreadIcon />} label="EMAIL" />
                                    <Tab sx={{ width: '50%', color: '#3b82f6' }} icon={<PersonPinIcon />} label="HEMIS" />
                                </Tabs>
                                <FormBox>
                                    {value === 0 ?
                                        <form>
                                            <LoginInputBox>
                                                <input id="email" className="form-control" type="text" placeholder="Email" />
                                                <button onClick={(e) => e.preventDefault()}><FaUser /></button>
                                            </LoginInputBox>
                                            <LoginInputBox>
                                                <input id="email_id" className="form-control" type={passworShowAndHiddien ? "text" : "password"} placeholder="Password" />
                                                <button onClick={(e) => {e.preventDefault(); setPasswordShowAndHidden(!passworShowAndHiddien)}}><MdRemoveRedEye /></button>
                                            </LoginInputBox>
                                            <button style={{backgroundColor: '#059669', color: '#fff'}} onClick={handleEmail} className="btn btn-success w-100 mt-2">LOGIN</button>
                                        </form>
                                        :
                                        <form>
                                            <LoginInputBox>
                                                <input id="hemis" className="form-control" type="text" placeholder="Hemis" />
                                                <button onClick={(e) => e.preventDefault()}><FaUser /></button>
                                            </LoginInputBox>
                                            <LoginInputBox>
                                                <input id="hemis_id" className="form-control" type={passworShowAndHiddien ? "text" : "password"} placeholder="Password" />
                                                <button onClick={(e) => {e.preventDefault(); setPasswordShowAndHidden(!passworShowAndHiddien)}}><MdRemoveRedEye /></button>
                                            </LoginInputBox>
                                            <button style={{backgroundColor: '#059669', color: '#fff'}} onClick={handleHemis} className="btn w-100 mt-2">LOGIN</button>
                                        </form>
                                    }
                                </FormBox>
                            </LoginForm>
                        </LoginRightBox>
                    </LoginRight>
                </LoginGrid>
            </div>
        </LoginBox>
    )
}

const LoginBox = styled.div`
    width: 100%;
    height: 100vh;
    background-image: url('./footer/patternbg.png');

    @media (max-width: 576px) {
        height: 50vh;
    }
`

const LoginGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 6fr 4fr;

    @media (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const LoginLeft = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #08AEEA;
    background: linear-gradient(to left, #1b2330 0%, rgb(13, 17, 24) 100%);
    color: #fff;
    padding: 25px;


    @media (max-width: 576px) {
        height: 50vh;
    }
`

const LoginRight = styled.div`
    width: 100%;
    height: 100vh;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 576px) {
        height: 50vh;
    }
`

const LoginRightBox = styled.div`
    width: 400px;
    min-height: 100px;
`

const LoginHeader = styled.div`
    width: 100%;
    display: grid;
    grid-template-rows: 4fr 1fr;
`

const LoginHeaderImg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
        padding: 10px;
        width: 150px;
    }
`

const LoginHeaderTitle = styled.div`
    h5 {
        text-align: center;
    }
`

const FormBox = styled.div`
    width: 100%;
    padding: 10px;

    form {
        input {
            margin-top: 10px;
        }
    }
`

const LoginForm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`



const LoginLeftBox = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 576px) {
        height: 50vh;
    }
`

const LoginLeftHeader = styled.div`
    width: 100%;
    padding: 10px;

    @media (max-width: 576px) {
        font-size: 22px;
    }
`

const LoginLeftText = styled.div`
    width: 100%;
    padding: 10px;
`

const AnimationText = styled.div`
    p {
        font-size: 32px;
    }

    @media (max-width: 576px) {
        p {
            font-size: 22px;
        }
    }
`

const LoginInputBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    button {
        border: none;
        padding: 7px 8px;
        margin-top: 10px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        color: #3b82f6;
    }
`

export default Login;