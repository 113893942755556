import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";


const Button = (props) => {
    const [linkBtn, setLinkBtn] = useState(props.link);
    const navigate = useNavigate();


    function Linked() {
        navigate(linkBtn);
    }

    return(
        <ButtonBox>
            <button onClick={Linked} className="btn btn-primary">{props.name}</button>
        </ButtonBox>
    )
}

const ButtonBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`


export default Button;