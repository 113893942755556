import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalStyle from "./styles/globalStyle";
import Home from "./pages/home";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Login from "./pages/login";
import SingleBook from "./pages/singleBook";
import OrderBook from "./pages/orderBook";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import Profile from "./pages/profile";
import CenterAbout from "./pages/centerAbout";
import CenterHistory from "./pages/centerHistory";
import ARM from "./pages/ARM";
import PublishingDepartment from "./pages/publishing_department";
import GatheringResources from "./pages/gatheringResources";
import LibraryResources from "./pages/libraryResources";
import ElectronicInformation from "./pages/electronicInformation";
import ForeignLibrary from "./pages/foreignLibrary";
import SingleNews from "./pages/singleNews";
import SinglePosts from "./pages/singlePosts";
import News from "./pages/news";
import Posts from './pages/posts';
import BackPage from "./pages/backPage";

import { useDispatch } from "react-redux";
import { UserStore } from "./store/user";
import AuthService from "./api/auth";


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Attendance from "./pages/attendance";
import Loader from "./pages/loader";
import ErrorPage from "./pages/errorPage";
import ScrollToTop from "./components/scrollToTop";
import BackPageLogin from "./pages/backPageLogin";
import Branch1 from "./pages/branch1";
import Menuscripts from "./pages/manuscripts";
import SingleMenuScript from "./pages/single-menuscript";
import Partners from "./pages/partners";


function App() {
  localStorage.setItem('lang', 'uzb');
  AOS.init();
  const dispatch = useDispatch();

  const getUser = async () => {
    try {
        const rees = await AuthService.getUser();
        dispatch(UserStore(rees?.result))
    } catch (error) {
          console.log(error)
      }
  }

  useEffect(() => {
      getUser()
  }, [])



  return (
    <>
      <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <ScrollToTop />
            <GlobalStyle />
            <ToastContainer />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/book/:id" element={<SingleBook />} />
              <Route path="/order-book" element={<OrderBook />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/center-about" element={<CenterAbout />} />
              <Route path="/center-history" element={<CenterHistory />} />
              <Route path="/arm-terms" element={<ARM />} />
              <Route path="/publishing_deportment" element={<PublishingDepartment />} />
              <Route path="/gathering-resrces" ou element={<GatheringResources />} />
              <Route path="/library-resources" element={<LibraryResources />} />
              <Route path="/electronic-information" element={<ElectronicInformation />} />
              <Route path="/foreign-library" element={<ForeignLibrary />} />
              <Route path="/single-news/:id" element={<SingleNews />} />
              <Route path="/single-post/:id" element={<SinglePosts />} />
              <Route path="/attendance" element={<Attendance />} />
              <Route path="/news" element={<News />} />
              <Route path="/posts" element={<Posts />} />
              <Route path="/post/:id" element={<Posts />} />
              <Route path="/loader" element={<Loader />} />
              <Route path="/admin" element={<BackPage />} />
              <Route path="/admin-login" element={<BackPageLogin />} />
              <Route path="/branch-1" element={<Branch1 />} />
              <Route path="/branch-2" element={<Branch1 />} />
              <Route path="/branch-3" element={<Branch1 />} />
              <Route path="/branch-4" element={<Branch1 />} />
              <Route path="/menuscripts" element={<Menuscripts />} />
              <Route path="/internationalbase" element={<Partners />} />
              <Route path="/single-menuscript/:id" element={<SingleMenuScript />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </BrowserRouter>
        </I18nextProvider>
    </>
  );
}

export default App;
