import React from "react";
import styled from "styled-components";
import Text from "../components/text";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";



const PublishingDepartment = () => {
    const { t } = useTranslation();


    return (
        <ARMBox>
            <Navbar />
            <div className="container">
                <ARMMain>
                    <ARMHeader className="arm-header" data-aos="zoom-out-right">
                        <h3>{t('PUBLISHING_DEPARTMENT')}</h3>
                    </ARMHeader>
                    <hr />
                    <div>
                        <Text delay={0} content={t('PUBLISHING_DEPARTMENT1')} />
                        <Text delay={50} content={t('PUBLISHING_DEPARTMENT2')} />
                        <Text delay={100} content={t('PUBLISHING_DEPARTMENT3')} />
                        <Text delay={150} content={t('PUBLISHING_DEPARTMENT4')} />
                        <Text delay={200} content={t('PUBLISHING_DEPARTMENT5')} />
                        <Text delay={250} content={t('PUBLISHING_DEPARTMENT6')} />
                        <Text delay={300} content={t('PUBLISHING_DEPARTMENT7')} />
                        <Text delay={350} content={t('PUBLISHING_DEPARTMENT8')} />
                        <Text delay={400} content={t('PUBLISHING_DEPARTMENT9')} />
                        <Text delay={450} content={t('PUBLISHING_DEPARTMENT10')} />
                        <Text delay={500} content={t('PUBLISHING_DEPARTMENT11')} />
                        <Text delay={550} content={t('PUBLISHING_DEPARTMENT12')} />
                        <Text delay={600} content={t('PUBLISHING_DEPARTMENT13')} />
                        <Text delay={750} content={t('PUBLISHING_DEPARTMENT14')} />
                        <Text delay={800} content={t('PUBLISHING_DEPARTMENT15')} />
                        <Text delay={850} content={t('PUBLISHING_DEPARTMENT16')} />
                        <Text delay={900} content={t('PUBLISHING_DEPARTMENT17')} />
                    </div>
                </ARMMain>
            </div>
            <Footer />
        </ARMBox>
    )
}


const ARMBox = styled.div`
    width: 100%;
    margin-top: 75px;
`

const ARMMain = styled.div`
    padding: 10px;
`

const ARMHeader = styled.div`
    width: 100%;
    
    h3 {
        margin: 0;
        padding: 10px;
    }
`


export default PublishingDepartment;