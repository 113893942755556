import { createSlice } from '@reduxjs/toolkit';



export const uploadImage = createSlice({
    name: 'uploadImage',

    initialState: {
        uploadImage: '',
    },

    reducers: {
        uploadImageFunc: (state, action) => {
            state.uploadImage = action.payload;
        }
    }
})


export const { uploadImageFunc } = uploadImage.actions


export default uploadImage.reducer;