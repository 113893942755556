import React, { useEffect, useState } from "react";
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import styled from "styled-components";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "./loader";




const SingleNews = () => {
    const params = useParams();
    const [singleNew, setSingleNew] = useState(null);
    const [titleString, setTitleString]= useState(null);
    const [title, setTitle]= useState(null);
    const [content, setContent] = useState(null);
    const [loader, setLoader] = useState(false);
    const [language, setLanguage] = useState(0);
    const [currentLang, setCurrentLang] = useState(localStorage.getItem('lang'))


    useEffect(() => {
        LanFunc();
    }, [currentLang])

    useEffect(() => {
        axios.get(`https://e-libraryrest.samdu.uz/api/News/${params?.id}`)
            .then(res => {
                setSingleNew(res?.data?.result);
                setTitleString(res?.data?.result.title)
                setLoader(true);
            })
            .catch(err => {
                console.log(err);
                setLoader(false);
            })
    }, [language])

    useEffect(()=>{
        if(titleString)
            setTitle(JSON.parse(titleString)[language].title)
    },[titleString])

    useEffect(() => {
        console.log(singleNew?.content)
        if(singleNew)
            setContent(JSON.parse(singleNew?.content))
    }, [singleNew?.content])

    function LanFunc() {
        if(currentLang === 'uzb') {
            setLanguage(0);
        }
        if(currentLang === 'rus') {
            setLanguage(2);
        }
        if(currentLang === 'en') {
            setLanguage(1);
        }
    }

    return (
        <div>
            <div>
                <Navbar />
            </div>
            {loader ? 
                <div className="container">
                    <SingleNewsBox>
                        <div>
                            <h1>{(titleString&&title)&&title}</h1>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: content ? content[language]?.content : '' }}></div>
                    </SingleNewsBox>
                </div>
                :
                <Loader />
            }
            <div>
                <Footer />
            </div>
        </div>
    )
}

const SingleNewsBox = styled.div`
    width: 100%;
    margin-top: 75px;
    padding: 20px;

    img {
        width: 100% !important;
    }
`

export default SingleNews;