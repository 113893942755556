import React from "react";
import { useTranslation } from "react-i18next";



const Text = (props) => {
    const { t } = useTranslation();

    return (
        <p data-aos="zoom-in-up" data-aos-delay={props.delay}>
            &nbsp; &nbsp; &nbsp; &nbsp; {t(`${props.content}`)}
        </p>
    )
}

export default Text;