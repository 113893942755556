import React from "react";
import Text from "../components/text";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";


const CenterHistory = () => {
    const { t } = useTranslation();



    return (
        <CenterHistoryBox>
            <Navbar />
            <div className="container">
                <CenterHistorySection>
                    <CenterHistoryHeader data-aos="zoom-out-right">
                        <h3>{t('CENTER_HISTORY')}</h3>
                    </CenterHistoryHeader>
                    <hr />
                    <CenterHistoryMain>
                        <Text delay={0} content={t('CENTER_HISTORY1')} />
                        <Text delay={50} content={t('CENTER_HISTORY2')} />
                        <Text delay={100} content={t('CENTER_HISTORY3')} />
                        <Text delay={150} content={t('CENTER_HISTORY4')} />
                        <Text delay={200} content={t('CENTER_HISTORY5')} />
                        <Text delay={250} content={t('CENTER_HISTORY6')} />
                        <Text delay={300} content={t('CENTER_HISTORY7')} />
                        <Text delay={350} content={t('CENTER_HISTORY8')} />
                        <Text delay={400} content={t('CENTER_HISTORY9')} />
                        <Text delay={450} content={t('CENTER_HISTORY10')} />
                        <Text delay={500} content={t('CENTER_HISTORY11')} />
                        <Text delay={550} content={t('CENTER_HISTORY12')} />
                        <Text delay={600} content={t('CENTER_HISTORY13')} />
                        <Text delay={650} content={t('CENTER_HISTORY14')} />
                        <Text delay={700} content={t('CENTER_HISTORY15')} />
                        <Text delay={750} content={t('CENTER_HISTORY16')} />
                    </CenterHistoryMain>
                </CenterHistorySection>
            </div>
            <Footer />
        </CenterHistoryBox>
    )
}


const CenterHistoryBox  = styled.div`
    margin-top: 75px;
    width: 100%;
`

const CenterHistoryHeader = styled.div`
    width: 100%;

    h3 {
        padding: 10px;
        margin: 0;
    }
`

const CenterHistorySection = styled.div`
    padding: 10px;
`

const CenterHistoryMain = styled.div`
    padding: 0px;
`


export default CenterHistory;