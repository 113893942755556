import React from 'react'
import Navbar from '../components/navbar'
import MainHeader from '../components/mainHeader'
import MainSearch from '../components/mainSearch'
import News from '../components/news';
import Category from '../components/category';
import Books from '../components/books'
import LoginHelper from '../components/login-helper';
import Information from '../components/information';
import Footer from '../components/footer';
import Button from '../components/btn';
import Posts from '../components/posts';

import { useTranslation } from 'react-i18next';


export default function Home() {
  const { t } = useTranslation();


  return (
    <div>
        <Navbar />
        <MainHeader />
        <MainSearch />
        <News />
        <Button link={'/news'} name={t('MORE')} />
        <Category />
        <Books grid={4} pagination={'main'} size={12}/>
        <Button link={'/order-book'} name={t('MORE')} />
        <Posts />
        <Button link={'/posts'} name={'Ko\'proq'} />
        <LoginHelper />
        <Information />
        <Footer />
    </div>
  )
}
