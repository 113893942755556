import React from "react";
import styled from "styled-components";
import Text from "../components/text";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";



const LibraryResources = () => {
    const { t } = useTranslation();




    return (
        <ARMBox>
            <Navbar />
            <div className="container">
                <ARMMain>
                    <ARMHeader data-aos="zoom-out-right">
                        <h3>{t('LIBRARY_RESOURCES')}</h3>
                    </ARMHeader>
                    <hr />
                    <div>
                        <Text delay={0} content={t('LIBRARY_RESOURCES1')} />
                        <Text delay={50} content={t('LIBRARY_RESOURCES2')} />
                        <Text delay={100} content={t('LIBRARY_RESOURCES3')} />
                        <Text delay={150} content={t('LIBRARY_RESOURCES4')} />
                        <Text delay={200} content={t('LIBRARY_RESOURCES5')} />
                        <Text delay={250} content={t('LIBRARY_RESOURCES6')} />
                        <Text delay={300} content={t('LIBRARY_RESOURCES7')} />
                        <Text delay={350} content={t('LIBRARY_RESOURCES8')} />
                        <Text delay={400} content={t('LIBRARY_RESOURCES9')} />
                        <Text delay={450} content={t('LIBRARY_RESOURCES10')} />
                        <Text delay={500} content={t('LIBRARY_RESOURCES11')} />
                        <Text delay={550} content={t('LIBRARY_RESOURCES12')} />
                        <Text delay={600} content={t('LIBRARY_RESOURCES13')} />
                        <Text delay={750} content={t('LIBRARY_RESOURCES14')} />
                    </div>
                </ARMMain>
            </div>
            <Footer />
        </ARMBox>
    )
}


const ARMBox = styled.div`
    width: 100%;
    margin-top: 75px;
`

const ARMMain = styled.div`
    padding: 10px;
`

const ARMHeader = styled.div`
    width: 100%;
    
    h3 {
        margin: 0;
        padding: 10px;
    }
`


export default LibraryResources;