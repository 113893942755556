import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";


const LoginHelper = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();


    function handleLogin() {
        navigate('/login')
    }


    return (
        <LoginHelperBox>
            <div className="container">
                <LoginHelperGrid>
                    <LoginHelperItemBox>
                        <h3>{t('SIGN_UP_HEADER')}</h3>
                        <p>{t('SIGN_UP_DISCRIPTION')}</p>
                        <button onClick={handleLogin} className="btn btn-primary">{t('SIGN_UP')}</button>
                    </LoginHelperItemBox>
                </LoginHelperGrid>
            </div>
        </LoginHelperBox>
    )
}


const LoginHelperBox = styled.div`
    width: 100%;
    height: 200px;
    background-image: url('./footer/patternbg.png');
    margin-top: 20px;
`

const LoginHelperGrid = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LoginHelperItemBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        text-align: center;
    }
`

export default LoginHelper;