import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../locales/en/translation.json';
import translationRus from '../locales/rus/translation.json';
import translationUzb from '../locales/uzb/translation.json';


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      rus: { translation: translationRus },
      uzb: { translation: translationUzb },
    },
    lng: 'uzb',
    fallbackLng: 'uzb',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
