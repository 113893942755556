import React from 'react'
import styled from 'styled-components'

export default function Header(props) {
  return (
    <HeaderComp>
        <h3>{props?.title}</h3>
    </HeaderComp>
  )
}


const HeaderComp = styled.div`
    h3 {
        &::before {
            content: '';
            position: absolute;
            width: 4px;
            height: 30px;
            background-color: blue;
            margin-left: -10px;
            margin-top: 1px;
        }
    }

    @media (max-width: 576px) {
      h3 {
        margin-left: 20px;
      }
    }
`