import { createSlice } from '@reduxjs/toolkit';



export const books = createSlice({
    name: 'Books',

    initialState: {
        books: [],
        book: [],
    },

    reducers: {
        BooksStore: (state, action) => {
            state.books = action.payload;
        },
        BookStore: (state, action) => {
            state.book = action.payload;
        }
    }
})


export const { BooksStore, BookStore } = books.actions


export default books.reducer;