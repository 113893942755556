import React, { useEffect, useState } from "react";
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import styleded from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// MUI Table
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// MUI Pagination
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const Menuscripts = () => {
    const [books, setBooks] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`https://e-libraryrest.samdu.uz/api/Ancient?pageNumber=${pageNumber}&pageSize=10`)
            .then(res => {
                setBooks(res?.data?.result);
            })
            .catch(err => {
                console.log(err)
            })
    }, [pageNumber])

    function setOpenBook(props) {
        navigate(`/single-menuscript/${props}`)
    }

    function paginationFunc(e, p) {
        setPageNumber(p - 1);
    }


    return (
        <div>
            <Navbar />
            <div className="container">
                <MenuscriptsBox>
                    <h3>Qo'lyozmalar</h3>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>№</StyledTableCell>
                                    <StyledTableCell align="right">Asar nomi</StyledTableCell>
                                    <StyledTableCell align="right">Muallifi</StyledTableCell>
                                    <StyledTableCell align="right">Kuchirilgan yoki yozilgan sanasi</StyledTableCell>
                                    <StyledTableCell align="right">Batafsil ma'lumot olish</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {books?.items && books?.items.map((row, index) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row">
                                            {++index}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{row.arab_name}<br />{row.work_name}</StyledTableCell>
                                        <StyledTableCell align="right">{row.author}</StyledTableCell>
                                        <StyledTableCell align="right">{row.date_write}</StyledTableCell>
                                        <StyledTableCell align="right"><button onClick={() => setOpenBook(row?.id)} className="btn btn-dark">Batafsil</button></StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <MenuscriptsPaginationBox>
                        <Stack spacing={2}>
                            <Pagination 
                                onChange={paginationFunc}
                                count={books?.totalPages} 
                                variant="outlined" 
                                shape="rounded" 
                            />
                        </Stack>
                    </MenuscriptsPaginationBox>
                </MenuscriptsBox>
            </div>
            <Footer />
        </div>
    )
}


const MenuscriptsBox = styleded.div`
    margin-top: 95px;

    h3 {
        margin-bottom: 20px;
    }
`

const MenuscriptsPaginationBox = styleded.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`


export default Menuscripts;