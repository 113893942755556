import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { IoSearchOutline } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import { useLocation } from "react-router-dom";
import { FiMinus } from "react-icons/fi";
import Books from '../components/books';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";





const OrderBook = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams =new URLSearchParams(location.search);
    const category1 = searchParams.get('Category');
    const [categoryDropDown, setCategoryDropDown] = useState(false);
    const [sortDropDown, setSortDropDown] = useState(false);
    const [bookName, setBookName] = useState('');
    const [bookTagName, setBookTagName] = useState('');
    const [bookAuthorName, setBookAuthorName] = useState('');
    const [bookPublisherName, setBookPublisherName] = useState('');
    const [bookUDKName, setBookUDKName] = useState('');
    const [bookISBNNumber, setBookISBNNumber] = useState('');
    const [bookPunlisherDate, setBookPublisherDate] = useState('');
    const [category, setCategory] = useState(category1 ? category1 : 1);
    const { t } = useTranslation();



    useEffect(() => {
        handleSort();
    }, [category])


    function handleSort() {
        navigate(`/order-book/?Book_Title=${bookName}&tag=${bookTagName}&Book_Author=${bookAuthorName}&Publisher=${bookPublisherName}&UDK=${bookUDKName}&ISBN_No=&Book_Inventory_No=${bookISBNNumber}&Publish_Date=${bookPunlisherDate}&Category=${category}&pageNumber=0&pageSize=12`)
    }


    return (
        <div className="order-book">
            <Navbar />
            <OrderBookBox>
                <OrderBookSearchBox>
                    <div className="container">
                        <OrderBookSearch>
                            <OrderBookHeaderSearch>
                                <OrderBookHeaderSearchTitle>
                                    <h2>{t('ORDER_BOOK_SEARCH_HEADER')}</h2>
                                </OrderBookHeaderSearchTitle>
                                <OrderBookHeaderSearchForms>
                                    {/* <select>
                                        <option>Art</option>
                                    </select>
                                    <select>
                                        <option>Year</option>
                                    </select> */}
                                    <input onChange={(e) => setBookName(e.target.value)} type="text" placeholder="Search..." />
                                    <button onClick={handleSort}><IoSearchOutline /></button>
                                </OrderBookHeaderSearchForms>
                            </OrderBookHeaderSearch>
                        </OrderBookSearch>
                    </div>
                </OrderBookSearchBox>
                <OrderBookMain>
                    <div className="container">
                        <OrderBookMainGrid>
                            <div className="order-book-left">
                                <BookNavHeader>
                                    <button onClick={() => setCategoryDropDown(!categoryDropDown)}>
                                        <h4>{t('CATEGORY')}</h4>
                                        <p>{categoryDropDown ? <FiMinus /> : <GoPlus />}</p>
                                    </button>
                                    {categoryDropDown && 
                                        <BookNavItems>
                                            <button onClick={() => setCategory(1)} data-aos="fade-right" data-aos-delay={0}>{t('BOOKS')}</button>
                                            <button onClick={() => setCategory(4)} data-aos="fade-right" data-aos-delay={50}>{t('ARTICLES')}</button>
                                            <button onClick={() => setCategory(3)} data-aos="fade-right" data-aos-delay={100}>{t('DISSERTATIONS')}</button>
                                            <button onClick={() => setCategory(2)} data-aos="fade-right" data-aos-delay={150}>{t('MONOGRAPHS')}</button>
                                        </BookNavItems>
                                    }
                                    <button onClick={() => setSortDropDown(!sortDropDown)}>
                                        <h4>{t('SORT')}</h4>
                                        <p>{sortDropDown ? <FiMinus /> : <GoPlus />}</p>
                                    </button>
                                    {sortDropDown && 
                                        <BookNavItems1>
                                            <input onChange={(e) => setBookName(e.target.value)} data-aos="fade-right" data-aos-delay={0} type="text" placeholder="Book name" />
                                            <input onChange={(e) => setBookTagName(e.target.value)} data-aos="fade-right" data-aos-delay={50} type="text" placeholder="Book tag" />
                                            <input onChange={(e) => setBookAuthorName(e.target.value)} data-aos="fade-right" data-aos-delay={100} type="text" placeholder="Book author" />
                                            <input onChange={(e) => setBookPublisherName(e.target.value)} data-aos="fade-right" data-aos-delay={150} type="text" placeholder="Book publisher" />
                                            <input onChange={(e) => setBookUDKName(e.target.value)} data-aos="fade-right" data-aos-delay={200} type="text" placeholder="Book UDK" />
                                            <input onChange={(e) => setBookISBNNumber(e.target.value)} data-aos="fade-right" data-aos-delay={250} type="text" placeholder="Book ISBN" />
                                            <input onChange={(e) => setBookPublisherDate(e.target.value)} data-aos="fade-right" data-aos-delay={300} type="text" placeholder="Book publisher data" />
                                            <button onClick={handleSort} data-aos="zoom-in" data-aos-delay={350}>{t('SORT')}</button>
                                        </BookNavItems1>
                                    }
                                </BookNavHeader>
                            </div>
                            <div className="order-book-right">
                                <Books grid={4}  size={12} />
                            </div>
                        </OrderBookMainGrid>
                    </div>
                </OrderBookMain>
            </OrderBookBox>
            <Footer />
        </div>
    )
}


const OrderBookBox =  styled.div`
    margin-top: 75px;
`

const OrderBookSearchBox = styled.div`
    width: 100%;
    min-height: 300px;
    background-color: #f2f2f2;
`


const OrderBookSearch = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
`

const OrderBookHeaderSearch = styled.div`
    width: 700px;
    min-height: 100px;
`

const OrderBookHeaderSearchTitle = styled.div`
    h2 {
        font-size: 22px;
        text-transform: uppercase;
    }
`

const OrderBookHeaderSearchForms = styled.div`
    display: grid;
    grid-template-columns: 4fr 0.5fr;

    select {
        padding: 10px 10px;
        border: none;
        border-right: 1px solid #f2f2f2;
    }

    input {
        border: none;
        outline: none;
        padding: 10px;
        padding-left: 10px;
        font-size: 14px;
    }

    button {
        border: none;
        background-color: #00ACC1;
        color: #fff;
        font-size: 22px;
    }

    @media (max-width: 576px) {
        grid-template-columns: 0.5fr 0.5fr 1fr 0.5fr;
    }
`

const OrderBookMain = styled.div`
    width: 100%;
    background-color: #fff;
    min-height: 400px;
`

const OrderBookMainGrid = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: 2fr 7fr;

    @media (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
    }

    /* > div {
        border: 1px solid #000;
        min-height: 400px;
    } */
`

const BookNavHeader = styled.div`
    width: 100%;
    margin-top: 5px;

    > button {
        width: 90%;
        margin: 0 auto;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
        padding: 5px 8px;
        text-transform: uppercase;


        h4 {
            font-size: 16px;
            padding: 0;
            margin: 0;
        }

        p {
            padding: 0;
            margin: 0;

            svg {
                font-size: 22px;
            }
        }
    }
`


const BookNavItems = styled.div`
    width: 90%;
    margin: 0 auto;

    button {
        width: 100%;
        border: none;
        margin-top: 5px;
        padding: 5px 8px;
        border-radius: 5px;
        background-color: #26A69A;
        color: #fff;
        text-transform: uppercase;
    }
`


const BookNavItems1 = styled.div`
    width: 90%;
    margin: 0 auto;

    input {
        display: block;
        width: 100%;
        border: none;
        box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, .1);
        padding: 5px 8px;
        border-radius: 5px;
        margin-bottom: 5px;
        outline: none;
        font-size: 14px;
    }

    button {
        width: 100%;
        border: none;
        margin-top: 5px;
        padding: 5px 8px;
        border-radius: 5px;
        background-color: #26A69A;
        color: #fff;
        text-transform: uppercase;
    }
`


export default OrderBook;