import { configureStore } from '@reduxjs/toolkit'
import books from './books';
import user from './user';
import uploadImage from './uploadImage';

export const store = configureStore({
  reducer: {
    books,
    user,
    uploadImage
  },
})
