import React from "react";
import styled from "styled-components";



const Loader = () => {
    return (
        <div>
            <Page>
                <Container>
                    <Right></Right>
                    <Right></Right>
                    <Right></Right>
                    <Right></Right>
                    <h3>Loading...</h3>
                </Container>
            </Page>     
        </div>
    )
}


const Page = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
`

const Container  = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    h3 {
        color: #040837;
    }
`

const Right = styled.div`
    width: 190px;
    height: 190px;
    border: 1px solid transparent;
    border-radius: 50%;
    position: absolute;

    &:nth-child(1) {
        border-bottom: 8px solid rgb(255, 141, 249);
        animation: rotate1 2s linear infinite;
    }

    @keyframes rotate1 {
        from {
            transform: rotateX(50deg) rotateZ(110deg);
        }

        to {
            transform: rotateX(50deg) rotateZ(470deg);
        }
    }

    &:nth-child(2) {
        border-bottom: 8px solid rgb(255,65,106);
        animation: rotate2 2s linear infinite;
    }

    @keyframes rotate2 {
        from {
            transform: rotateX(20deg) rotateY(50deg) rotateZ(20deg);
        }

        to {
            transform: rotateX(20deg) rotateY(50deg) rotateZ(380deg);
        }
    }

    &:nth-child(3) {
        border-bottom: 8px solid rgb(0,255,255);
        animation: rotate3 2s linear infinite;
    }

    @keyframes rotate3 {
        from {
            transform: rotateX(40deg) rotateY(130deg) rotateZ(450deg);
        }

        to {
            transform: rotateX(40deg) rotateY(130deg) rotateZ(90deg);
        }
    }

    &:nth-child(4) {
        border-bottom: 8px solid rgb(252, 183, 55);
        animation: rotate4 2s linear infinite;
    }

    @keyframes rotate4 {
        from {
            transform: rotateX(70deg) rotateZ(270deg);
        }

        to {
            transform: rotateX(70deg) rotateZ(630deg);
        }
    }
`

export default Loader;