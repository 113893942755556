import React, { useState } from "react";
import Header from "./header";
import styled from "styled-components";
import { FaBookOpen } from "react-icons/fa6";
import { GiBookshelf } from "react-icons/gi";
import { SiGitbook } from "react-icons/si";
import { AiFillBook } from "react-icons/ai";
import { GiSpellBook } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Category = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    

    function handleCategory() {
        navigate(`/order-book/?Book_Title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=${1}&pageNumber=${0}&pageSize=${12}`)
    }

    function handleCategory1() {
        navigate(`/order-book/?Book_Title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=${4}&pageNumber=${0}&pageSize=${12}`)
    }

    function handleCategory2() {
        navigate(`/order-book/?Book_Title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=${3}&pageNumber=${0}&pageSize=${12}`)
    }

    function handleCategory3() {
        navigate(`/order-book/?Book_Title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=${2}&pageNumber=${0}&pageSize=${12}`)
    }

    function handleCategory4() {
        navigate(`/menuscripts`)
    }



    return (
        <>
            <div className="container">
                <Header title={t('CATEGORY')} />
            </div>
            <CategoryBox>
                <div className="container">
                    <CategoryGrid>
                        <CategoryItem data-aos="zoom-in" data-aos-delay="0" onClick={handleCategory}>
                            <CategoryItemBox>
                                <CategoryImageBox>
                                    <FaBookOpen />
                                </CategoryImageBox>
                                <CategoryHeaderBox>
                                    <h5>{t('BOOKS')}</h5>
                                </CategoryHeaderBox>
                            </CategoryItemBox>
                        </CategoryItem>
                        <CategoryItem data-aos="zoom-in" data-aos-delay="100" onClick={handleCategory1}>
                            <CategoryItemBox>
                                <CategoryImageBox>
                                    <GiBookshelf />
                                </CategoryImageBox>
                                <CategoryHeaderBox>
                                    <h5>{t('ARTICLES')}</h5>
                                </CategoryHeaderBox>
                            </CategoryItemBox>
                        </CategoryItem>
                        <CategoryItem data-aos="zoom-in" data-aos-delay="200" onClick={handleCategory2}>
                            <CategoryItemBox>
                                <CategoryImageBox>
                                    <SiGitbook />
                                </CategoryImageBox>
                                <CategoryHeaderBox>
                                    <h5>{t('DISSERTATIONS')}</h5>
                                </CategoryHeaderBox>
                            </CategoryItemBox>
                        </CategoryItem>
                        <CategoryItem data-aos="zoom-in" data-aos-delay="300" onClick={handleCategory3}>
                            <CategoryItemBox>
                                <CategoryImageBox>
                                    <AiFillBook />
                                </CategoryImageBox>
                                <CategoryHeaderBox>
                                    <h5>{t('MONOGRAPHS')}</h5>
                                </CategoryHeaderBox>
                            </CategoryItemBox>
                        </CategoryItem>
                        <CategoryItem data-aos="zoom-in" data-aos-delay="400" onClick={handleCategory4} >
                            <CategoryItemBox>
                                <CategoryImageBox>
                                    <GiSpellBook />
                                </CategoryImageBox>
                                <CategoryHeaderBox>
                                    <h5>{t('MANUSCRIPTS')}</h5>
                                </CategoryHeaderBox>
                            </CategoryItemBox>
                        </CategoryItem>
                    </CategoryGrid>
                </div>
            </CategoryBox>
        </>
    )
}


const CategoryBox = styled.div`
    width: 100%;
    min-height: 250px;
    background-image: url('./footer/patternbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

const CategoryGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    margin-top: 20px;
    padding-top: 20px;

    @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

const CategoryItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`

const CategoryItemBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const CategoryImageBox = styled.div`
    width: 150px;
    height: 150px;
    position: relative;
    border-radius: 50%;
    transition: all 1s;
    background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7);
    color: #fff;

    &:hover {
        transform: scale(1.1);

        svg {
            transform: translate(-50%, -50%) scale(1.1) rotate(360deg);
        }
    }


    svg {
        width: 100px;
        font-size: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 1s;
    }
`

const CategoryHeaderBox = styled.div`
    h5 {
        padding-top: 10px;
        font-size: 14px;
    }
`

export default Category;