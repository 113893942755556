import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import USA from '../images/flags/us.svg';
import RUS from '../images/flags/ru.svg';
import UZB from '../images/flags/uz.svg'

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [dropDownLan, setDropDownLan] = useState(false);


  const changeLanguage = (lng) => {
    localStorage.setItem('lang', lng);
    i18n.changeLanguage(localStorage.getItem('lang'));
    setDropDownLan(false);
  };

  function handleLan() {
    setDropDownLan(!dropDownLan);
  }


  return (
    <SelectLanguageBox>
      <button onClick={handleLan}>
        <SelectLanBtnBox>
          {localStorage.getItem('lang') === 'en' ? <button className='btn btn-success'><img src={USA} alt='USA flag' />English</button> : ''}
          {localStorage.getItem('lang') === 'rus' ? <button className='btn btn-success'><img src={RUS} alt='RUS flag' />Русский</button> : ''}
          {localStorage.getItem('lang') === 'uzb' ? <button className='btn btn-success'><img src={UZB} alt='UZB flag' />Uzbek</button> : ''}
        </SelectLanBtnBox>
      </button>
      {dropDownLan && 
        <SelectLanDropDownBox>
          {localStorage.getItem('lang') !== 'en' ? <button className='btn btn-success' onClick={() => changeLanguage('en')}><img src={USA} alt='USA flag' />English</button> : ''}
          {localStorage.getItem('lang') !== 'rus' ? <button className='btn btn-success' onClick={() => changeLanguage('rus')}><img src={RUS} alt='RUS flag' />Русский</button> : ''}
          {localStorage.getItem('lang') !== 'uzb' ? <button className='btn btn-success' onClick={() => changeLanguage('uzb')}><img src={UZB} alt='UZB flag' />Uzbek</button> : ''}
        </SelectLanDropDownBox>
      }
    </SelectLanguageBox>
  );
}

const SelectLanguageBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

    > button {
      background-color: transparent;
      border: none;
      color: #fff;
      font-size: 22px;
    }
  
  @media (max-width: 576px) {
    justify-content: end;
  }
`

const SelectLanBtnBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    font-size: 16px;
    display: flex;
    margin-right: 3px;
    align-items: center;

    img {
      margin-right: 3px;
      width: 15px;
      display: inline-block;
    }
  }

  @media (max-width: 576px) {
    button {
      font-size: 9px;
      padding: 4px 8px;

      img {
        width: 10px;
      }
    }
  }
`

const SelectLanDropDownBox = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  top: 30px;
  right: 0px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  > button {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 5fr;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    img {
      padding-right: 3px;
      width: 15px;
      display: inline-block;
    }
  }
`


export default LanguageSelector;
