import React from "react";
import styled from "styled-components";
import Logo from '../images/navbar-images/logo/samdu.webp'
import Header from "./header";
import { FaYoutube } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";


const Footer = () => {
    const { t } = useTranslation();




    return (
        <FooterBox>
            <div className="container">
                <FooterGrid>
                    <FooterLeft>
                        <FooterLeftBox>
                            <img src={Logo} alt="footer-logo" />
                            <h3>SamDU ARBM</h3>
                        </FooterLeftBox>
                    </FooterLeft>
                    <FooterRight>
                        <div>
                            <Header title={t('LINKS')} />
                            <ul>
                                <li><FaAngleRight /> e-{t('BOOK')}</li>
                                <li><FaAngleRight /> {t('HOME')}</li>
                                <li><FaAngleRight /> Samdu.uz</li>
                                <li><FaAngleRight /> Hemis.samdu.uz</li>
                            </ul>
                        </div>
                        <div className="footer-address">
                            <Header title={t('ADDRESS')} />
                            <ul>
                                <li>227 S Marion St.</li>
                                <li>Oak Park, IL, 60302</li>
                                <li>
                                    <iframe title="SamDU_Map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12288.865445745065!2d66.961105!3d39.644845!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f4d18d2c22d77cb%3A0x5cfaf9320fc9a299!2sSamarkand%20State%20University%2C%2015%20University%20Boulevard%2C%20Samarkand%2C%20Uzbekistan!5e0!3m2!1sen!2sus!4v1699865820437!5m2!1sen!2sus" width="100%" height="200" style={{border: '1px solid #333', borderRadius: '10px'}} allowfullscreen={""} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </li>
                                <li>{t('MAP')}</li>
                            </ul>
                        </div>
                        <div className="contacts">
                            <Header title={t('NETWORKS')} />
                            <ul>
                                <li><FaYoutube style={{color: 'red'}} /> YouTube</li>
                                <li><FaFacebookSquare style={{color: '#1877F2'}} /> FaceBook</li>
                                <li><FaInstagram style={{background: 'linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7)', color: '#fff', borderRadius: '5px'}} /> Instagram</li>
                                <li><FaTelegram style={{color: '#0088cc'}} /> Telegram</li>
                            </ul>
                        </div>
                    </FooterRight>
                </FooterGrid>
            </div>
        </FooterBox>
    )
}


const FooterBox = styled.div`
    width: 100%;
    min-height: 400px;
    background-image: url('./footer/patternbg.png');
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 20px;
`

const FooterGrid = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: 2fr 6fr;

    @media (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const FooterLeft = styled.div`
    width: 100%;
    display: flex;
    min-height: 400px;
    justify-content: center;
    align-items: center;

`

const FooterLeftBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: 150px;
    }

    h3 {
        text-align: center;
        font-family: 'Dancing Script', cursive;
        font-weight: 700;
    }
`

const FooterRight = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    div {
        padding: 10px;
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            padding: 5px 0;

            svg {
                font-size: 22px;
            }
        }
    }

    @media (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

export default Footer;