import React from 'react'
import Fade, { Zoom } from 'react-reveal'
import mainHeaderImg from '../images/main-header/2345127-removebg-preview.webp'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { FaDownload } from "react-icons/fa";




export default function MainHeader() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function handleMainHeaderBtn() {
        navigate(`/order-book`)
    }


  return (
    <MianHeaderClass style={{marginTop: '100px'}}>
        <div className='container'>
            <MainHeaderGrid>
                <MainHeaderLeft>
                    <MainHeaderDisktop>
                        <div>
                            <Fade left delay={1500}>
                                <h1>{t('MAIN_HEADER')}<br /><span>{t('MAIN_HEADER2')}</span></h1>
                            </Fade>
                            <Fade left delay={3000}>
                                <p>{t('MAIN_HEADER_DESCRIPTION')}</p>
                            </Fade>
                            <MainHeaderButton>
                                <button onClick={handleMainHeaderBtn} style={{display: 'flex', justifyContent: 'space-between'}} className='btn btn-success'>{t('ORDER_A_BOOK')} <i class='bx bx-right-arrow-alt'></i></button>
                                <Link to='https://play.google.com/store/apps/details?id=com.companyname.samdu_books' style={{color: '#000'}}>
                                    <button className='btn border'><FaDownload style={{marginRight: "5px"}} /> {t('DOWNLOAD_APP')}</button>
                                </Link>
                            </MainHeaderButton>
                        </div>
                        <div>
                            <Zoom>
                                <MainHeaderRight>
                                    <img src={mainHeaderImg} alt='main-header-img' />
                                </MainHeaderRight>
                            </Zoom>
                        </div>
                    </MainHeaderDisktop>
                </MainHeaderLeft>
                <MainHeaderMobile>
                    <div>
                        <Zoom>
                            <MainHeaderRight>
                                <img src={mainHeaderImg} alt='main-header-img' />
                            </MainHeaderRight>
                        </Zoom>
                    </div>
                    <div>
                        <MainHeaderLeft>
                            <Fade left delay={1500}>
                                <h1>{t('MAIN_HEADER')}<br /><span>{t('MAIN_HEADER2')}</span></h1>
                            </Fade>
                            <Fade left delay={3000}>
                                <p>{t('MAIN_HEADER_DESCRIPTION')}</p>
                            </Fade>
                            <MainHeaderButton>
                                <button onClick={handleMainHeaderBtn} className='btn btn-success'>{t('ORDER_A_BOOK')} <i class='bx bx-right-arrow-alt'></i></button>
                                <Link to='https://play.google.com/store/apps/details?id=com.companyname.samdu_books' style={{color: '#000'}}>
                                    <button className='btn border'><FaDownload style={{marginRight: "5px"}} /> {t('DOWNLOAD_APP')}</button>
                                </Link>
                            </MainHeaderButton>
                        </MainHeaderLeft>
                    </div>
                </MainHeaderMobile>
            </MainHeaderGrid>
        </div>
    </MianHeaderClass>
  )
}

const MianHeaderClass = styled.div`
    width: 100%;
    background-color: #fff;
`

const MainHeaderGrid = styled.div`

`

const MainHeaderLeft = styled.div`
    padding: 10px;

    h1 {
        font-size: 62px;
        font-weight: 700;
        margin-top: 100px;

        span {
            color: #388E3C;
        }
    }

    p {
        margin-top: 20px;
    }

    button {
        display: flex;
        align-items: center;
        padding: 8px;
        margin-right: 5px;
    }

    @media (max-width: 576px) {
        h1 {
            font-size: 18px;
            margin-top: 30px;
        }

        p {
            margin-top: 5px;
            font-size: 8px;
        }
    }
`

const MainHeaderRight = styled.div`
    img {
        width: 95%;
        animation: mainHeaderImg 2s linear infinite;

        @keyframes mainHeaderImg {
            0% {
                transform: translateY(30px);
            } 50%{
                transform: translateY(10px);
            } 100% {
                transform: translateY(30px);
            }
        }
    }
`

const MainHeaderButton = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    margin-top: 60px;

    @media (max-width: 576px) {
        margin-top: 20px;

        button {
            padding: 3px 5px;
            font-size: 8px;
        }

        a {
            width: max-content;
        }
    }
`

const MainHeaderDisktop = styled.div`
    display: block;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
    @media (max-width: 576px) {
        display: none;
    }
`

const MainHeaderMobile = styled.div`
    display: none;


    @media (max-width: 576px) {
        display: block;
    }
`