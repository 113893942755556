import React, { useEffect, useState } from "react";
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import styleded from "styled-components";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

// MUI Table
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// MUI Pagination
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const data = [
    {
        label: "Web of Science",
        urls: [
            "https://www.webofscience.com/wos/woscc/basic-search"
        ]
    },
    {
        label: "Scopus",
        urls: [
            "https://www.scopus.com/home.uri"
        ]
    },
    {
        label: "Springer Nature",
        urls: [
            "https://link.springer.com/"
        ]
    },
    {
        label: "Ebscohost",
        urls: [
            "https://www.ebsco.com/",
            "https://search.ebscohost.com/",
            "https://www.ebsco.com/products/ebscohost-research-platform"
        ]
    },
    {
        label: "GoogleScholar",
        urls: [
            "https://scholar.google.com/"
        ]
    },
    {
        label: "ProQuest",
        urls: [
            "https://www.proquest.com/",
            "https://library.usu.edu/find/databases/"
        ]
    },
    {
        label: "ReseachGate",
        urls: [
            "https://www.researchgate.net/"
        ]
    },
    {
        label: "Academia",
        urls: [
            "https://www.academia.edu/"
        ]
    }
]



const Partners = () => {
    const { t } = useTranslation();



    return (
        <div>
            <Navbar />
            <div className="container">
                <MenuscriptsBox>
                    <h3>{t('PARTNERS')}</h3>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {/* <StyledTableCell>№</StyledTableCell> */}
                                    <StyledTableCell align="left">Nomi</StyledTableCell>
                                    <StyledTableCell align="left">Manzili</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        {/* <StyledTableCell component="th" scope="row">
                                            {++index}
                                        </StyledTableCell> */}
                                        <StyledTableCell align="left"><h5>{row.label}</h5></StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.urls.map((url, index)=>(
                                                <><Link to={url} key={index}>{url}</Link><br/></>
                                            ))}
                                            </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MenuscriptsBox>
            </div>
            <Footer />
        </div>
    )
}


const MenuscriptsBox = styleded.div`
    margin-top: 95px;

    h3 {
        margin-bottom: 20px;
    }
    a {
        color:blue;
    }
`

const MenuscriptsPaginationBox = styleded.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`


export default Partners;