import { createSlice } from '@reduxjs/toolkit';



export const user = createSlice({
    name: 'Books',

    initialState: {
        user: []
    },

    reducers: {
        UserStore: (state, action) => {
            state.user = action.payload;
        }
    }
})


export const { UserStore } = user.actions


export default user.reducer;