import React from "react";
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import styled from "styled-components";
import Text from "../components/text";
import { useTranslation } from "react-i18next";




const Branch1 = () => {
    const { t } = useTranslation();


    return (
        <div className="branch">
            <Navbar />
            <div className="container">
                <BranchBox>
                    <BranchHeader data-aos="zoom-out-right">
                        <h3>{t('BRANCH1_HEADER')}</h3>
                    </BranchHeader>
                    <Text delay={0} content={t('BRANCH1_TEXT1')} />
                    <Text delay={50} content={t('BRANCH1_TEXT2')} />
                    <Text delay={100} content={t('BRANCH1_TEXT3')} />
                    <Text delay={150} content={t('BRANCH1_TEXT4')} />
                    <Text delay={200} content={t('BRANCH1_TEXT5')} />
                    <Text delay={250} content={t('BRANCH1_TEXT6')} />
                    <Text delay={350} content={t('BRANCH1_TEXT7')} />
                    <Text delay={400} content={t('BRANCH1_TEXT8')} />
                </BranchBox>
            </div>
            <Footer />
        </div>
    )
}


const BranchBox = styled.div`
    margin-top: 75px;
`

const BranchHeader = styled.div`
    padding-top: 20px;
`


export default Branch1;