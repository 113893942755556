import axios from "./axios";

const AuthService = {
    async getUser() {
        const {data} = await axios.get('/api/User/LoginWithAccessToken',{headers:{
            Authorization:`Bearer ${localStorage.getItem('token')}`
        }})
        return data
    }
}

export default AuthService;