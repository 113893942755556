import React from "react";
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import styled from "styled-components";
import Text from "../components/text";
import { useTranslation } from "react-i18next";


const CenterAbout = () => {
    const { t } = useTranslation();



    return(
        <>
            <Navbar />
            <AboutCenterBox className="container">
                <AboutCenterMain>
                    <AboutCenterHeader data-aos="zoom-out-right">
                        <h3>{t('CENTER_ABOUT')}</h3>
                    </AboutCenterHeader>
                    <hr />
                    <div className="about-center-information">
                        <Text delay={0} content={'CENTER_ABOUT1'} />
                        <Text delay={50} content={'CENTER_ABOUT2'} />
                        <Text delay={100} content={'CENTER_ABOUT3'} />
                        <Text delay={150} content={'CENTER_ABOUT4'} />
                        <Text delay={200} content={'CENTER_ABOUT5'} />
                        <Text delay={250} content={'CENTER_ABOUT6'} />
                        <Text delay={300} content={'CENTER_ABOUT7'} />
                        <Text delay={350} content={'CENTER_ABOUT8'} />
                        <Text delay={400} content={'CENTER_ABOUT9'} />
                        <Text delay={450} content={'CENTER_ABOUT10'} />
                    </div>
                </AboutCenterMain>
            </AboutCenterBox>
            <Footer />
        </>
    )
}

const AboutCenterBox = styled.div`
    width: 100%;
    margin-top: 75px;
`

const AboutCenterMain = styled.div`
    padding: 10px;
`

const AboutCenterHeader = styled.div`
    width: 100%;

    h3 {
        margin: 0;
        padding: 10px;
    }
`

export default CenterAbout;